<template>
  <div>
    <!-- tab栏 -->
    <van-tabs @click="onClickOnlyOne">
      <van-tab title="社区活动">
        <div class="information">
          <!-- 全部 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <router-link :to="'/activityLog/detail/' + item.AId + '/1'">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.AImgUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden"
            class="hidden">── 没有更多数据了 ──</div>
        </div>
      </van-tab>
      <van-tab title="党组活动">
        <div class="information">
          <!-- 全部 -->
          <div class="center"
            v-for="item in lists"
            :key="item.index">
            <router-link :to="'/activityLog/detail/' + item.AId + '/2'">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.AImgUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden"
            class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <van-tab title="志愿者活动">
        <div class="information">
          <!-- 全部 -->
          <div class="center"
            v-for="item in lists"
            :key="item.index">
            <router-link :to="'/activityLog/detail/' + item.AId + '/2'">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.AImgUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden"
            class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WeGetMySignupActivityPage,
  WeGetMySignupActivityPages,
} from "@/api/Activity";
import { Toast } from "vant";
export default {
  data () {
    return {
      akId: 0, //tab栏
      list: {
      }, //资讯列表
      lists: {},
      listfrom: {
        page: 1,
        limit: 10,
        status: 0,
        akId: 0,
        openID: this.$route.query["openid"],
      }, //查询传参
      hidden: true, //没有更多数据了
      listfroms: {
        page: 1,
        limit: 10,
        status: 0,
        openID: this.$route.query["openid"],
      },
    };
  },
  methods: {
    onClickOnlyOne (name, title) {
      console.log(name);
      if (name == '0') {
        this.list = []
        this.listfrom.page = 1
        this.listfrom.limit = 10
        this.listfrom.status = 0
        this.listfrom.akId = 0
        this.listfrom.openID = this.$route.query["openid"]
        this.getList()
      }
      if (name == '1') {
        this.lists = []
        this.listfroms.page = 1
        this.listfroms.limit = 10
        this.listfroms.status = 0
        this.listfroms.openID = this.$route.query["openid"]
        this.getLists()
      }
      if (name == '2') {
        this.list = []
        this.listfrom.page = 1
        this.listfrom.limit = 10
        this.listfrom.status = 0
        this.listfrom.akId = 1
        this.listfrom.openID = this.$route.query["openid"]
        this.getList()
      }
    },
    // 获取分页列表
    getList () {
      WeGetMySignupActivityPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImgUrl == "") {
                  this.list[i].AImgUrl = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => { });
    },
    getLists () {
      WeGetMySignupActivityPages(this.listfroms)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.lists = {};
            } else {
              this.lists = res.data.data;
              for (var i = 0; i < this.lists.length; i++) {
                if (this.lists[i].AImgUrl == "") {
                  this.lists[i].AImgUrl = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => { });
    },
    // tan点击切换事件
    onClick (e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted () {
    this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>